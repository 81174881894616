import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCourtesyClassCredit } from "../../api/main/courtesyClassCredits/useCourtesyClassCredit";
import { useCourtesyClassFinderViewModel } from "../../api/main/scheduledClasses/viewModels/useCourtesyClassFinderViewModel";
import { joinScheduledClassConfig } from "../../configure/joinScheduledClassConfig";
import moment from "moment";
import { useSearchParams } from "../../shared/useURLSearchParams";
import { useChild } from "../../api/main/children/useChild";
import { useProfile } from "../../api/main/profiles/useProfile";
import { useLocationDistances } from "../../api/main/classLocations/useLocationDistances";
import { JoinCourtesyClassMessages } from "../membersClassFinder/MembersClassFinderClass";
import { Banner } from "../shared/banner/Banner";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { Alert, Button, CardImg, CardImgOverlay, CardTitle, Col, Row } from "reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClassFinderCoreLocation } from "../classFinderCore/ClassFinderCoreLocation";

export const CourtesyClassFinder = () => {
    const { childId, classTypeId, courtesyClassCreditId } = useParams<{ childId: string | undefined, classTypeId: string | undefined, courtesyClassCreditId: string | undefined; }>();
    const { t } = useTranslation();

    // Load the CourtesyClassCredit
    const {
        data: {
            model: courtesyClassCredit
        }, isLoading: _isLoading, errors: loadErrors
    } = useCourtesyClassCredit(courtesyClassCreditId);

    const {
        data: {
            items: _allItems,
            scheduledClassChildren,
            childAbsences,
            classTypes,
            classStages,
            classSubStages,
            classLocations,
            childAttendances
        }, isLoading: isLoadingClasses, errors: classesLoadErrors
    } = useCourtesyClassFinderViewModel({ termId: courtesyClassCredit?.termId });

    // Load the child - The member can be routed here from one of their children, if that is the case we want to load that child, so we can find suitable classes
    const {
        data: {
            model: storeChild
        }, isLoading: isLoadingChild, errors: childLoadErrors
    } = useChild(childId);

    const {
        data: {
            model: memberProfile
        }, errors: memberProfileLoadErrors
    } = useProfile(storeChild?.memberId ?? '');

    const {
        data: {
            items: locationDistances
        }, errors: locationDistancesLoadErrors
    } = useLocationDistances({ postcodeOrCity: memberProfile?.postcode.split(' ')[0] ?? '' });
    const isLoading = _isLoading || isLoadingClasses || isLoadingChild;

    // Combine the data into a single model
    const allItems = useMemo(() => _allItems?.map(item => {
        // Depending on if we are looking at the current or next term, we want the special _classStageId and _classSubStageId to
        // be set to the relevant field.
        const _classStageId = item.currentClassStageId;
        const _classSubStageId = item.currentClassSubStageId;

        const _nextLessonDateWithSpacesAvailable = item.currentLessonNumber >= joinScheduledClassConfig.canJoinCurrentTermUntilLessonNumber ? item.nextLessonDateWithSpacesAvailableForNextTerm : item.nextLessonDateWithSpacesAvailableForCurrentTerm;

        // Follow the various lookups for related data.
        const classType = classTypes?.find(it => it.id === item.classTypeId);
        const classLocation = classLocations?.find(it => it.id === item.classLocationId);
        const activeClassStage = classStages?.find(it => it.id === _classStageId);
        const activeClassSubStage = classSubStages?.find(it => it.id === _classSubStageId);

        const allChildren = scheduledClassChildren?.filter(it => it.scheduledClassId === item.id);

        const today = moment().toISOString();
        const allAbsences = childAbsences?.filter(it => it.scheduledClassId === item.id && it.endDate > today);

        // Return everything as a single object.
        return {
            ...item,

            _classStageId,
            _classSubStageId,

            _nextLessonDateWithSpacesAvailable,

            classType,
            classLocation,
            classStage: activeClassStage,
            classSubStage: activeClassSubStage,

            allChildren,
            allAbsences
        };
    }), [_allItems, classTypes, classLocations, classStages, classSubStages, scheduledClassChildren, childAbsences]);

    // Manage the tab, classType and classLocation being displayed
    const {
        classType: classTypeParam,
        classLocation: classLocationParam,
        day: dayParam,
    } = useSearchParams();

    // Filter ClassTypes
    const [filterClassType] = useState<string | null | undefined>(classTypeParam ?? classTypeId ?? '');

    // Filter ClassLocations
    const [filterClassLocation] = useState<string | null | undefined>(classLocationParam ?? '');

    // Filter Day
    const [filterDay] = useState<string | null | undefined>(dayParam ?? '');

    // Filtering
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        // Filter out the deferrals class location
        ret = ret.filter(it => it.classLocation?.name !== 'Deferrals - DO NOT REMOVE');

        // Filtering ClassTypes
        if (filterClassType !== 'all' && filterClassType !== '') {
            ret = ret.filter(it => it.classTypeId === filterClassType);
        }

        // Filtering ClassLocations
        if (filterClassLocation !== 'all' && filterClassLocation !== '') {
            ret = ret.filter(it => it.classLocationId === filterClassLocation);
        }

        // Filtering the ClassType passed as props
        if (classTypeId) {
            ret = ret.filter(it => it.classTypeId === classTypeId);
        }

        // Filtering for Day
        if (filterDay !== 'all' && filterDay !== '') {
            ret = ret.filter(it => it.dayOfWeek === parseInt(filterDay ?? '0'));
        }

        // Filtering for Availability - Taking childAbsences into account
        ret = ret.filter(it => it.maximumClassSize - (it.allChildren.length - it.allAbsences.length) >= 1);

        // Filtering classes with valid dates
        ret = ret.filter(it => {

            let dates = it?.lessonDatesForCurrentTerm?.map(dateString => new Date(dateString)) ?? [];

            const classIsFullOfCubs = !!((it?.allChildren?.length ?? 0) >= (it?.maximumClassSize ?? 10));
            const childAbsences = it?.allAbsences ?? [];
            const courtesyClassBookings = childAttendances ?? [];

            // Remove the first and last lesson date in the term
            dates = dates.slice(1, dates.length - 1);

            // Remove past dates
            const today = new Date();
            dates = dates.filter(date => date >= today);

            // Remove classes that start in less than 30 minutes
            dates = dates.filter(date => {
                const now = new Date();
                const timeDifference = (date.getTime() - now.getTime()) / 60000;
                return timeDifference >= 30;
            });

            // Filter out dates that are full, including courtesy class bookings
            dates = dates.filter(date => {
                const currentAttendeesCount = it?.allChildren?.length ?? 0;

                const courtesyBookingsForDate = courtesyClassBookings.filter(booking =>
                    moment(booking.lessonDate).isSame(date, 'day')
                ).length;

                return (currentAttendeesCount + courtesyBookingsForDate) < it?.maximumClassSize;
            });

            if (classIsFullOfCubs) {
                dates = dates.filter(date =>
                    childAbsences.some(absence =>
                        moment(date).isBetween(moment(absence.startDate), moment(absence.endDate), 'day', '[]')
                    )
                );
            }

            // Grouping and sorting dates (optional if you need months elsewhere)
            const months = dates.reduce((acc, curr) => {
                const monthName = moment(curr).format('MMMM');
                const monthDates = acc.find(it => it.monthName === monthName);

                if (monthDates) {
                    monthDates.dates.push(curr);
                } else {
                    acc.push({ monthName, dates: [curr] });
                }

                return acc;
            }, [] as { monthName: string, dates: Date[]; }[]);

            months.forEach(it => {
                it.dates.sort((a, b) => a.getDate() - b.getDate());
            });

            return dates.length > 0;
        });

        return ret;
    }, [allItems, filterClassType, filterClassLocation, classTypeId, filterDay, childAttendances]);

    // Calculate the child's age in years and months, to enable finding suitable classStages
    const ageOfChild = useMemo(() => {
        return {
            months: moment().diff(storeChild?.dateOfBirth, 'months'),
            years: moment().diff(storeChild?.dateOfBirth, 'years')
        };
    }, [storeChild]);

    // Generate an Array of suitableClasses, based on the Child's age.
    const suitableClasses = useMemo(() => {
        // Find all the ClassStages the Child can join based on their age
        const suitableClassStages = classStages?.filter(classStage => {
            const startAgeMonths = (classStage.startAgeYears * 12) + (classStage.startAgeMonths);
            const endAgeMonths = (classStage.endAgeYears * 12) + (classStage.endAgeMonths);

            if (ageOfChild.months >= startAgeMonths && ageOfChild.months <= endAgeMonths) {
                return true;
            }
            return false;
        });
        const suitableClassStagesIds = suitableClassStages?.map(it => it.id);

        // Filter the items to only include those that are in the suitableClassStages
        let ret = items.filter(item => suitableClassStagesIds?.includes(item._classStageId ?? ''));

        // If we are booking a courtesy class, we only want to show classes with spaces in a lesson.
        ret = ret.filter(item => !!item._nextLessonDateWithSpacesAvailable);

        return ret;
    }, [classStages, ageOfChild, items]);

    const [joinCourtesyClassMessages] = useState<JoinCourtesyClassMessages>({ errorMessage: undefined, warningMessage: undefined, successMessage: undefined });

    // Locations from suitableClasses
    const suitableLocations = useMemo(() => {
        const classes = suitableClasses.map(it => it.classLocationId).filter((value, index, self) => self.indexOf(value) === index);

        // Sort classes based on distance from member
        classes.sort((a, b) => {
            const distanceA = locationDistances?.find(locationDistance => locationDistance.classLocationId === a)?.distanceFromPoint;
            const distanceB = locationDistances?.find(locationDistance => locationDistance.classLocationId === b)?.distanceFromPoint;

            return parseFloat(distanceA ?? '1000') - parseFloat(distanceB ?? '1000');
        });

        return classes;
    }, [suitableClasses, locationDistances]);

    const title = t('courtesyClassFinder.title', 'Courtesy class finder');

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1 style={{ fontSize: '3rem' }}>
                                {title}
                            </h1>
                            <h3>{t('courtesyClassFinder.subTitle', 'for {{firstName}} {{lastName}}', { firstName: storeChild?.firstName, lastName: storeChild?.lastName })}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid className="p-0">
                <AlertOnErrors errors={[
                    loadErrors, classesLoadErrors, childLoadErrors,
                    memberProfileLoadErrors, locationDistancesLoadErrors
                ]} />

                <Alert color="warning">
                    <h5>{t('courtesyClassFinder.info.title', 'Courtesy class policy')}</h5>
                    <ul>
                        <li>{t('courtesyClassFinder.info.p1', 'Each bear cub can join a maximum of 2 courtesy classes per term.')}</li>
                        <li>{t('courtesyClassFinder.info.p2', 'No courtesy classes are offered in the first or last days of a term.')}</li>
                        <li>{t('courtesyClassFinder.info.p3', 'Courtesy classes cannot be carried over into another term.')}</li>
                        <li>{t('courtesyClassFinder.info.p4', 'Courtesy classes are only offered where space is available in a suitable class.')}</li>
                        <li>{t('courtesyClassFinder.info.p5', 'If you cannot attend a pre-arranged courtesy class it still counts as 1 of your 2 courtesy classes.')}</li>
                    </ul>
                </Alert>

                <ConditionalFragment showIf={!!isLoading}>
                    <Row>
                        <Col></Col>

                        <Col xs="auto" className="class-finder-core-loading-message">
                            <FontAwesomeIcon icon="magnifying-glass" color="#fada00" />
                            <> </>
                            {t('common.courtesyClassFinder.loadingMessage', 'Searching our classes to find some suitable matches for your cub...')}
                            <> </>
                            <LoadingIndicator />
                        </Col>

                        <Col></Col>
                    </Row>
                </ConditionalFragment>

                <ConditionalFragment showIf={
                    !!joinCourtesyClassMessages.errorMessage ||
                    !!joinCourtesyClassMessages.warningMessage ||
                    !!joinCourtesyClassMessages.successMessage
                }>
                    <Alert color={
                        joinCourtesyClassMessages.errorMessage ? 'danger' :
                            joinCourtesyClassMessages.warningMessage ? 'warning' : 'success'
                    }>
                        <ConditionalFragment showIf={!!joinCourtesyClassMessages.successMessage}>
                            {joinCourtesyClassMessages.successMessage}
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!joinCourtesyClassMessages.warningMessage}>
                            {joinCourtesyClassMessages.warningMessage}
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!joinCourtesyClassMessages.errorMessage}>
                            {joinCourtesyClassMessages.errorMessage}
                        </ConditionalFragment>
                    </Alert>
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && !!filterClassType}>
                    <Row className="class-finder-core">
                        <Col xs={12} md={6} className="class-finder-core-header">
                            <CardImg
                                alt="Card image cap"
                                src={`/img/originals/${classTypes?.find(it => it.id === filterClassType)?.name.split(' ').join('')}Main.jpg`}
                                top
                                className="class-finder-core-header-img"
                            />
                            <CardImgOverlay>
                                <CardTitle className="class-finder-core-header-title" tag="h5">
                                    <Row>
                                        <Col style={{ marginBottom: '0' }}>
                                            <p>{classTypes?.find(it => it.id === filterClassType)?.name}</p>
                                        </Col>
                                    </Row>
                                </CardTitle>
                            </CardImgOverlay>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!suitableClasses.length}>
                        <Row>
                            <Col></Col>

                            <Col xs="auto" className="class-finder-core-no-suitable-classes-message">
                                {t('courtesyClassFinder.noSuitableClasses.message', 'Hello. Classes for your child’s age group may be unavailable. Please click the button below to contact us, and we’ll do our best to help find a suitable class for you or answer any questions you may have.')}
                            </Col>

                            <Col></Col>
                        </Row>

                        <Row>
                            <Col></Col>

                            <Col xs="auto" className="class-finder-core-no-suitable-classes-message">
                                <Button color="primary">
                                    <a href="https://www.happycubs.ie/contact-us/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#737373', fontWeight: 'bold' }}>
                                        {t('common.anyQuestionsContactUs', 'Any Questions? Contact Us')}
                                    </a>
                                </Button>
                            </Col>

                            <Col></Col>
                        </Row>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!!suitableClasses.length}>
                        {
                            suitableLocations?.map(item => {
                                const location = classLocations?.find(it => it.id === item);
                                const classesForThisLocation = suitableClasses?.filter(it => it.classLocationId === item);
                                const distance = locationDistances?.find(locationDistance => locationDistance.classLocationId === item)?.distanceFromPoint;

                                return <ClassFinderCoreLocation
                                    locationId={item}
                                    location={location}
                                    suitableClasses={classesForThisLocation}
                                    distance={distance}
                                    childDateOfBirth={storeChild?.dateOfBirth}
                                    childId={childId}
                                    isForCourtesyClass={true}
                                    courtesyClassCreditId={courtesyClassCreditId} />;
                            })
                        }
                    </ConditionalFragment>
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};