import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { CourtesyClassFinderViewModelQuery, CourtesyClassFinderViewModelQueryVariables } from '../../generated/graphql';
import { childAbsenceFieldsFragment } from "../../models/ChildAbsence";
import { childAttendanceFieldsFragment } from "../../models/ChildAttendance";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";

export interface CourtesyClassFinderViewModelOptions extends AsyncLoadOptions {
    termId?: string | undefined | null;
}

export function useCourtesyClassFinderViewModel(options: CourtesyClassFinderViewModelOptions = {}): AsyncLoadResult<CourtesyClassFinderViewModelQuery> {
    // Query the data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<CourtesyClassFinderViewModelQuery, CourtesyClassFinderViewModelQueryVariables>(
        gql`
        query courtesyClassFinderViewModelQuery ($termId: ID!) {
           items: scheduledClasses (termId: $termId) {
               ...scheduledClassFields

               lessonDatesForCurrentTerm

               nextLessonDateWithSpacesAvailableForCurrentTerm
               nextLessonDateWithSpacesAvailableForNextTerm
           }

           scheduledClassChildren (termId: $termId) {
               id
               scheduledClassId
               childId
           }

           childAbsences (termId: $termId) {
               ...childAbsenceFields
           }

           classTypes {
               ...classTypeFields
           }

           classStages {
               ...classStageFields
           }

           classSubStages {
               ...classSubStageFields
           }

           classLocations {
               ...classLocationFields
           }

           childAttendances (termId: $termId) {
               ...childAttendanceFields
           }
        }

        ${scheduledClassFieldsFragment}
        ${childAbsenceFieldsFragment}
        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classLocationFieldsFragment}
        ${childAttendanceFieldsFragment}
        `,
        {
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            variables: {
                termId: options.termId ?? Guid.empty,
            },
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};