import { RouteEntry } from "../../shared/routes";
import { SwapClassFinder } from "../classFinderCore/ClassFinderCore";
import { CourtesyClassFinder } from "../courtesyClassFinder/CourtesyClassFinder";
import { MembersChildrenAddChild } from "./addChild/MembersChildrenAddChild";
import { EditMemberChild } from "./edit/EditMemberChild";
import { MembersChildTab } from "./membersChild/MembersChildTab";
import { MembersChildrenList } from "./MembersChildrenList";

export const membersChildrenRoutes: Array<RouteEntry> = [
    { path: '/my/children/:childId', element: <EditMemberChild />, authorize: true },
    { path: '/my/children/details/:id', element: <MembersChildTab />, authorize: true, },
    { path: '/my/children/add/:memberId', element: <MembersChildrenAddChild />, authorize: true, },
    { path: '/my/children', element: <MembersChildrenList />, authorize: true, },

    // CourtesyClassFinder
    { path: '/my/courtesy-class-finder/:childId/:classTypeId/:courtesyClassCreditId', element: <CourtesyClassFinder />, authorize: true },

    // SwapClassFinder
    { path: '/my/swap-class-finder/:childId/:classTypeId/:scheduledClassId', element: <SwapClassFinder />, authorize: true }
];
